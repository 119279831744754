.navbarWrapper {
  color: aliceblue;
  background-color: #2b032f;
  display: flex;
  justify-content: space-between;
  height: 8vh;
  width: 100%;
}
.logos {
  top: 5%;
  display: flex;
  width: 6.5%;
  margin-left: 3%;
  justify-content: space-between;
}
.logo1 {
  display: flex;
  align-items: center;
  width: 45%;
  /* padding: 0.2em; */
}
.logo2 {
  display: flex;
  align-items: center;
  width: 45%;
  margin-left: 1%;
}
#img1,
#img2 {
  width: 100%;
}

.nav-links {
  display: flex;
  width: 100%;
  /* margin-left: 54vw; */
  /* padding-left: 1vw; */
  
}

.pageNames {
  width: 60%;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 31vw;
}

.nav-links li {
  list-style-type: none;
  display: flex;
  /* margin-left: 2vw; */
  align-items:flex-start;
  /* justify-self: flex-end; */
  font-size: 1.25vw;
 
  /* align-content: center; */
  /* justify-content: center; */
}
.mobile {
  display: none;
}
.active-links {
  color: white;
  text-decoration: underline;
  text-decoration-color: #E5D536;
  text-decoration-thickness: 5px;
}
.links {
  text-decoration: none;
  color: white;
}
@media (max-width: 760px) {
  .nav-links {
    display: flex;
    flex-direction: column;
    /*padding-right: 0;*/
    width: 20%;
  }
  .pageNames {
    display: none;
  }
  .navbar {
    height: 8vh;
  }
  .logos {
    width: 10%;
    padding: 1rem 0.5rem;
  }
  .mobile {
    display: inline-block;
    position: absolute;
    position: fixed;
    z-index: 100;
    top: 1%;
    left: 90%;
  }
  .mob-icon {
    position: absolute;
    z-index: 50;
    transition: width 2s ease-in-out,;
  }
  .nav-links li {
    display: none;
  }
}
.ham {
  overflow: hidden;
  overflow-y: hidden;
  animation-name: anim ;
  animation-duration: 2s;
  /* animation-direction: reverse; */
  
  /* position: absolute; */
  position: fixed;
  top: 0;
  right: 0vw;
  z-index: 20;
  background: transparent;
  height: 100vh;
  background-color: black;
  width: 50vw;
  margin-left: 62vw;
}
@keyframes anim {
  from {
    width: 0vw;
  }
  to {
    width: 50vw;
  }
}
.nav-i {
  overflow: hidden;
  animation-name: anim;
  animation-duration: 2s;
  /* animation-direction: reverse; */
}
.nav-i li {
  justify-content: space-between;
  list-style-type: none;
  margin-top: 3vh;
  animation: anim 1s;
  color: aliceblue;
  font-size: 20px;
  font-weight: 700;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.events {
  width: 193px;
  height: 54px;
  margin-left: 42vw;
  margin-top: 2vh;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 55px;
  text-align: center;
  color: aliceblue;
}
.event-down {
  margin-top: 2vh;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: aliceblue;
}
.event-namecontainer {
  font-family: "Helvetica";
  font-style: normal;
  display: flex;
  margin-top: 5vh;
}
.event-name {
  padding-left: 7vw;
}
.caseStudy {
  display: flex;
  flex-direction: row;
  width: 978px;
  height: 490px;
  margin-left: 31vw;
  background-image: linear-gradient(116.24deg, #076089 28.12%, #222150 93.23%);
  border-radius: 25px 0px;
  margin-top: 3vh;
  color: aliceblue;
}
.heading {
  padding: 2vw;
  height: 318px;
  width: 475px;
}
.vertical-line {
  border: 1px;
  background-image: linear-gradient(
    rgba(245, 245, 245, 0),
    rgba(245, 245, 245, 0.5073),
    rgba(245, 245, 245, 0)
  );
  width: 1.2px;
  margin-top: 12vh;
  height: 340px;
}
.img h1 {
  color: rgba(26, 26, 26, 1);
  margin: 3vw;
  margin-left: 7vw;
}
.img img {
  margin-left: 5vw;
}
