html,
body {
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url(./Fonts/Inter-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"), url(./Fonts/Helvetica.ttf) format("truetype");
}
